<template>
  <div class="trips-body py-5" v-if="item">
    <div class="pb-2 d-flex" v-if="!viewOnly">
      <v-icon
        size="40"
        class="cursor-pointer ml-auto"
        @click="collapse"
        color="black"
        >mdi-progress-close</v-icon
      >
    </div>

    <div class="trips-body-content">
      <div class="main-item pt-3">
        <div class="main-img">
          <img
            v-if="item.data && item.data.coverImage"
            width="100%"
            height="100%"
            class="object-cover cursor-pointer"
            :src="item.data.coverImage.url"
            @click="coverImageDialogHandler"
          />
        </div>
      </div>
      <v-row :key="item.data._id">
        <v-col cols="12" :md="12">
          <div class="mt-5">
            <div class="main-title d-flex align-center justify-space-between">
              <span
                class="main-title d-block my-2 black--text transition-3s font-20 font-500"
                >{{ item.data.title[$root.language] }}</span
              >
              <div class="d-flex" v-if="!viewOnly">
                <div
                  v-if="actions.includes('delete')"
                  class="icon-cover mr-1 cursor-pointer base-btn"
                  @click="deleteConfirm"
                >
                  <v-icon color="error">mdi-delete</v-icon>
                </div>
                <div
                  v-if="actions.includes('edit')"
                  class="icon-cover mr-1 cursor-pointer base-btn"
                  @click="editHandler"
                >
                  <v-icon color="black-grey">mdi-pencil</v-icon>
                </div>
              </div>
            </div>
            <p class="black--text font-15 mt-3">
              {{ item.data.name[$root.language] }}
            </p>
            <p class="gray--text font-14">
              {{ item.data.description[$root.language] }}
            </p>
          </div>
          <div>
            <div class="d-block">
              <p class="font-500 black-gray--text">Tags</p>
            </div>
            <div class="d-flex">
              <div>
                <div
                  class="rounded mr-2 pa-0 secondary d-block mb-2"
                  style="float: left"
                  v-for="(tag, index) in item.data.tags"
                  :key="index"
                >
                  <p class="pa-0 mx-2 my-1">{{ tag.title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="line mb-7"></div>
          <div class="details-needtoknow pb-5">
            <span class="d-block black-grey--text font-500 font-15 mt-4"
              >NEED TO KNOW</span
            >
            <div v-if="item.data.startTime">
              <p class="font-14 green--text ma-0">
                <span class="font-12 black-grey--text">Starts at </span>
                {{ item.data.startTime }}
              </p>
            </div>
            <div v-if="item.data.endTime">
              <p class="font-14 green--text ma-0">
                <span class="font-12 black-grey--text">Ends at </span>
                {{ item.data.endTime }}
              </p>
            </div>
            <span class="d-block black-grey--text font-500 font-15 mt-4"
              >STATS</span
            >
            <div
              v-for="item in item.data.stats || []"
              :key="item._id"
              class="d-flex align-center justify-space-between"
            >
              <div class="d-flex align-center ma-0">
                <span class="black-grey--text font-14 ma-0"
                  >{{ item.statType.name[$root.language] }}:

                  <span
                    v-if="
                      item &&
                      item.statType &&
                      item.statType.selections &&
                      item.statType.selections.length > 0
                    "
                    class="font-14 green--text ma-0"
                    >{{
                      item.statType.selections.find((s) => s._id == item.value)
                        ?.value[$root.language]
                    }}
                  </span>
                  <span v-else class="font-14 green--text ma-0"
                    >{{ item.value }}{{ ` ${item.unit || ""}` }}
                  </span>
                </span>
              </div>
            </div>

            <div
              class="d-flex align-center justify-space-between mb-2 mt-5"
              v-if="
                item.data &&
                item.data.newPricing &&
                item.data.newPricing.startPriceInUsd
              "
            >
              <div class="d-flex align-center">
                <span class="white-grey--text font-15 font-500">PRICE</span>
              </div>
              <span class="font-22 black--text"
                >$
                {{ item.data.newPricing.startPriceInUsd.toLocaleString("en") }}
              </span>
            </div>

            <span class="d-block black-grey--text font-500 font-15 mt-4"
              >INCLUDED</span
            >
            <template v-for="option in item.data.options || []">
              <div :key="option._id" v-if="option.included">
                <span class="green--text font-14 d-block">{{
                  option.description[$root.language]
                }}</span>
              </div>
            </template>

            <div class="mt-4">
              <span
                class="white-grey--text font-15 font-500"
                v-if="item.data.options.some((option) => !option.included)"
                >EXTRAS</span
              >
              <template v-for="option in item.data.options || []">
                <div
                  v-if="!option.included"
                  class="extra-cover d-flex align-center justify-space-between lightblue px-3 py-2 mb-2"
                  :key="option._id"
                >
                  <span class="white--text font-14 font-500 d-block">{{
                    option.description[$root.language]
                  }}</span>
                  <span class="white--text font-14 font-500 d-block"
                    ><b>+ $ {{ option.price }}</b></span
                  >
                </div>
              </template>
            </div>
          </div>
          <div class="line mb-7"></div>
          <div class="details-steps">
            <v-tabs v-model="selectedDay" centered class="mb-5">
              <v-tabs-slider color="secondary"></v-tabs-slider>

              <v-tab
                v-for="(day, i) in item.data.schedules || []"
                :key="i"
                @click="selectDayHandler(day)"
              >
                Day {{ i + 1 }}
              </v-tab>
            </v-tabs>
            <div
              v-if="
                item.data &&
                item.data.schedules &&
                item.data.schedules.length > 0 &&
                item.data.schedules[selectedDay] &&
                item.data.schedules[selectedDay].day &&
                item.data.schedules[selectedDay].day.schedule &&
                item.data.schedules[selectedDay].day.schedule.length > 0
              "
            >
              <div class="mb-5">
                <GmapMap
                  v-if="
                    item.data.schedules[selectedDay].day.schedule.some(
                      (item) => item.latitude
                    ) &&
                    item.data.schedules[selectedDay].day.schedule.some(
                      (item) => item.longitude
                    )
                  "
                  :center="{
                    lat: Number(
                      item.data.schedules[selectedDay].day.schedule.find(
                        (item) => item.latitude
                      )?.latitude
                    ),
                    lng: Number(
                      item.data.schedules[selectedDay].day.schedule.find(
                        (item) => item.longitude
                      )?.longitude
                    )
                  }"
                  :zoom="10"
                  style="width: 100%; height: 300px"
                >
                  <GmapMarker
                    :key="index"
                    v-for="(marker, index) in markers || []"
                    :position="{
                      lat: Number(marker.latitude),
                      lng: Number(marker.longitude)
                    }"
                  />
                </GmapMap>
                <div
                  v-else
                  style="
                    width: 100%;
                    height: 300px;
                    border: 1px solid gray;
                    border-radius: 5px;
                  "
                >
                  <p class="font-16 text-center red--text">
                    Markers are missing
                  </p>
                </div>
              </div>

              <div class="steps">
                <div class="step-line"></div>
                <v-row>
                  <!-- <v-col
                  cols="12"
                  md="6"
                  v-for="(day, i) in item.data.schedules"
                  :key="i"
                > -->
                  <div class="mb-8 ml-2">
                    <div
                      v-for="(schedule, j) in item.data.schedules[selectedDay]
                        .day.schedule"
                      :key="j"
                    >
                      <div class="w-100">
                        <div class="d-flex align-center justify-space-between">
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <div
                              class="step-min b-cover px-2 py-1 radius-20 mr-2"
                            >
                              <v-icon color="secondary"
                                >mdi-{{
                                  transportIcon(schedule.transport)
                                }}</v-icon
                              >
                            </div>
                            <span class="secondary--text font-12 font-500">{{
                              schedule.location
                            }}</span>
                          </div>
                        </div>

                        <div class="pl-7 mt-2">
                          <span class="font-20 font-500 d-block black--text">{{
                            schedule.title[$root.language]
                          }}</span>
                          <p class="font-12 white-grey--text">
                            {{ schedule.description[$root.language] }}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div
                          class="item ml-8 mb-3 cursor-pointer"
                          @click="didSelectAttraction(schedule.attraction)"
                        >
                          <div class="item-cover pb-3">
                            <div class="item-img radius-5">
                              <img
                                v-if="schedule.attraction.coverImage"
                                :src="schedule.attraction.coverImage.url"
                                alt=""
                              />
                            </div>
                            <div class="pb-0 px-3">
                              <span
                                class="main-title d-block mt-2 black--text transition-3s font-18 font-500"
                                >{{ schedule.attraction.name[$root.language] }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- </v-col> -->
                </v-row>
              </div>
            </div>
            <div class="details-whatto mt-5">
              <!-- what to bring -->
              <div
                class="item-cover px-3 py-2 mb-2 purple"
                v-if="item.data.bring.length > 0"
              >
                <span class="white--text font-14 font-500">
                  WHAT TO BRING
                </span>
              </div>
              <div class="px-4 py-2">
                <div v-for="detail in item.data.bring" :key="detail._id">
                  <span class="font-14 black-grey--text"
                    >• {{ detail.label[$root.language] }}</span
                  >
                </div>
              </div>

              <!-- what to wear -->
              <div
                class="item-cover px-3 py-2 mb-2 darkblue"
                v-if="item.data.wear.length > 0"
              >
                <span class="white--text font-14 font-500">WHAT TO WEAR</span>
              </div>
              <div class="px-4 py-2">
                <div v-for="detail in item.data.wear" :key="detail._id">
                  <span class="font-14 black-grey--text"
                    >• {{ detail.label[$root.language] }}</span
                  >
                </div>
              </div>
              <!-- what to expect -->

              <div
                class="item-cover px-3 py-2 mb-2 orange"
                v-if="item.data.expect.length > 0"
              >
                <span class="white--text font-14 font-500">WHAT TO EXPECT</span>
              </div>
              <div class="px-4 py-2">
                <div v-for="detail in item.data.expect" :key="detail._id">
                  <span class="font-14 black-grey--text"
                    >• {{ detail.label[$root.language] }}</span
                  >
                </div>
              </div>
            </div>

            <v-dialog
              v-model="photosDialog"
              fullscreen
              hide-overlay
              transition="scale-transition"
              content-class="full-dialog"
            >
              <dialog-photos
                :key="photosDialog"
                :images="images"
                type="experience"
                @close="photosDialog = false"
              ></dialog-photos>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="photosDialog"
        :images="images"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
  <div v-else style="height: 100vh" class="pt-5">
    <v-skeleton-loader type="card"></v-skeleton-loader>
    <v-col cols="12" md="12" v-for="(item, i) in [1, 2, 3, 4, 5, 6]" :key="i">
      <v-skeleton-loader type="text"></v-skeleton-loader>
    </v-col>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";
export default {
  props: {
    item: {
      type: [Object],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["delete", "edit"]
    }
  },
  computed: {
    markers() {
      let arr = [];
      this.item.data.schedules.forEach((day) => {
        day.day.schedule.forEach((sh) => {
          arr.push(sh);
        });
      });
      return arr;
    }
  },
  components: { DialogPhotos },
  data: () => ({
    photosDialog: false,
    images: {},
    deleteDialog: false,
    selectedDay: "",
    selectedDayObject: {}
  }),
  methods: {
    transportIcon(transport) {
      if (transport == "drive") {
        return "car";
      }
      if (transport == "walk") {
        return "walk";
      }
      if (transport == "swim") {
        return "swim";
      }
      if (transport == "eat") {
        return "walk";
      } else {
        return "";
      }
    },
    selectDayHandler(day) {
      this.selectedDayObject = day.day;
    },
    viewItem() {
      this.editDialog = true;
    },
    deleteConfirm() {
      this.deleteDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        `/library-activity/${this.item.data._id}`
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    editHandler() {
      this.$emit("editTrip");
    },
    coverImageDialogHandler() {
      this.images = { url: this.item.data.coverImage };
      this.photosDialog = !this.photosDialog;
    },
    photosDialogHandler(images) {
      this.photosDialog = true;
      this.images = images;
    },
    collapse() {
      this.$emit("collapse");
    },
    didSelectAttraction(attraction) {
      this.$router.push(`/attractions/${attraction._id}`);
    }
  },
  async created() {}
};
</script>

<style lang="scss" scoped>
.trips-body {
  .extra-cover {
    border-radius: 20px;
    max-width: 180px;
  }

  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .trips-body-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .main-item {
      img {
        border-radius: 5px;
        overflow: hidden;
        height: 300px;
      }
      .main-title {
        .icon-cover {
          border-radius: 3px;
          background: $graybg;
          padding: 4px;
          border: 1px solid $grayicon;
          transition: 0.2s;
          &:hover {
            background: #f3f3f3;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: $grayicon;
    }

    .item {
      white-space: pre-wrap;
      padding: 7px;
      border: 1px solid rgba($grayicon, 0.4);
      border-radius: 5px;
      .item-cover {
        overflow: hidden;

        .item-img {
          height: 200px;
          max-height: 200px;
          overflow: hidden;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }

        &:hover {
          .item-img {
            img {
              transform: scale(1.1);
            }
          }
          .main-title {
            color: $secondary !important;
          }
        }
      }
    }
  }
  .steps {
    position: relative;

    .step-line {
      content: "";
      position: absolute;
      left: 15px;
      top: 10px;
      height: calc(100% - 130px);
      width: 2px;
      background: $grayicon;
    }

    .step-min {
      position: relative;
      z-index: 9;
      background: white;
    }
  }
}
</style>
